import axios from "axios";
import { Collection, Environment, Account, Message, MessageEvent, AvailableEnvironment, User, Context, SolutionImport, Analytics } from "./types";
import { AvailablePhoneNumber } from "./types/AvailablePhoneNumber";
import { PhoneNumber } from "./types/PhoneNumber";
import { Provider } from "./types/Provider";

export * from './types'

const getQueryString = (data?: { [x: string]: any }): string => {

  if (!data || Object.keys(data).length === 0) {
    return ""
  }

  return "?" + Object.keys(data).map(key => {
    if (data[key]) {
      return key + "=" + data[key]
    }
    return null;
  }).filter(x => x != null).join('&');
}

export const getContext = async (): Promise<Context> => {
  let response = await axios.get<Context>('context')
  return response.data
}
export const createCurrentUser = async (): Promise<User> => {
  let response = await axios.post<User>('users/me')
  return response.data
}

export const createAccount = async (data: { [x: string]: any }): Promise<string> => {
  let response = await axios.post<string>(`accounts`, data)
  return response.data
}

export const getAccounts = async (data?: { [x: string]: string }): Promise<Collection<Account>> => {
  let response = await axios.get<Collection<Account>>(`accounts${getQueryString(data)}`)
  return response.data
}
export const getAccountById = async (id: string): Promise<Account> => {
  let response = await axios.get<Account>(`accounts/${id}`)
  return response.data
}
export const updateAccount = async (id: string, data: { [x: string]: any }): Promise<string> => {
  let response = await axios.put<string>(`accounts/${id}`, data)
  return response.data
}
export const getMessages = async (data?: { [x: string]: string }): Promise<Collection<Message>> => {
  let response = await axios.get<Collection<Message>>(`messages${getQueryString(data)}`)
  return response.data
}
export const getMessageById = async (id: string): Promise<Message> => {
  let response = await axios.get<Message>(`messages/${id}`)
  return response.data
}
export const getMessageEvents = async (id: string, data?: { [x: string]: string }): Promise<Collection<MessageEvent>> => {
  let response = await axios.get<Collection<MessageEvent>>(`messages/${id}/events${getQueryString(data)}`)
  return response.data
}
export const getEnvironments = async (data?: { [x: string]: string }): Promise<Collection<Environment>> => {
  let response = await axios.get<Collection<Environment>>(`environments${getQueryString(data)}`)
  return response.data
}
export const getEnvironmentById = async (id: string): Promise<Environment> => {
  let response = await axios.get<Environment>(`environments/${id}`)
  return response.data
}
export const createEnvironment = async (data: { [x: string]: any }): Promise<string> => {
  let response = await axios.post<string>(`environments`, data)
  return response.data
}
export const updateEnvironment = async (id: string, data: { [x: string]: any }): Promise<string> => {
  let response = await axios.put<string>(`environments/${id}`, data)
  return response.data
}
export const syncEnvironment = async (id: string): Promise<string> => {
  let response = await axios.post<string>(`environments/${id}/sync`)
  return response.data
}
export const getImport = async (id: string): Promise<SolutionImport> => {
  let response = await axios.get<SolutionImport>(`imports/${id}`)
  return response.data
}
export const createImport = async (environmentId: string): Promise<string> => {
  let response = await axios.post<string>(`imports`, { environmentId: environmentId })
  let importId = response.data
  let iterations = 0;
  while (iterations < 90) {
    var solutionImport = await getImport(importId);
    if (solutionImport.status === 'Success' || solutionImport.status === 'Failure') {
      if (solutionImport.errorCode === 80071151) {
        throw new Error("Another solution is currently importing. Please try again later.")
      }
      break;
    } else {
      iterations++
      await new Promise(res => setTimeout(res, 10000));
    }
  }

  if (iterations === 90) { // Wait for up to 15 minuntes, then fail.
    throw new Error("Solution failed to import within 15 minutes.")
  }

  return response.data
}
export const setEnvironmentVariables = async (id: string, data: { [x: string]: any }): Promise<boolean> => {
  let response = await axios.post<boolean>(`environments/${id}/variables`, {
    variables: data
  });
  return response.data
}
export const getAvailableEnvironments = async (): Promise<Collection<AvailableEnvironment>> => {
  let response = await axios.get<Collection<AvailableEnvironment>>('environments/available')
  return response.data
}
export const getUsers = async (data?: { [x: string]: string }): Promise<Collection<User>> => {
  let response = await axios.get<Collection<User>>(`users${getQueryString(data)}`)
  return response.data
}
export const getUserById = async (id: string): Promise<User> => {
  let response = await axios.get<User>(`users/${id}`)
  return response.data
}

export const getProviders = async (data?: { [x: string]: string }): Promise<Collection<Provider>> => {
  let response = await axios.get<Collection<Provider>>(`providers${getQueryString(data)}`)
  return response.data
}
export const getProviderById = async (id: string): Promise<Provider> => {
  let response = await axios.get<Provider>(`providers/${id}`)
  return response.data
}
export const createProvider = async (data: { [x: string]: any }): Promise<string> => {
  let response = await axios.post<string>(`providers`, data)
  return response.data
}
export const updateProvider = async (id: string, data: { [x: string]: any }): Promise<string> => {
  let response = await axios.put<string>(`providers/${id}`, data)
  return response.data
}
export const searchProviderPhoneNumbers = async (providerId: string, data?: { [x: string]: any }): Promise<AvailablePhoneNumber[]> => {
  let response = await axios.get<AvailablePhoneNumber[]>(`providers/${providerId}/phoneNumbers/search${getQueryString(data)}`)
  return response.data
}
export const acquireProviderPhoneNumber = async (providerId: string, data: { [x: string]: any }): Promise<boolean> => {
  let response = await axios.post<boolean>(`providers/${providerId}/phoneNumbers/acquire`, data)
  return response.data
}
export const getPhoneNumbers = async (data?: { [x: string]: string }): Promise<Collection<PhoneNumber>> => {
  let response = await axios.get<Collection<PhoneNumber>>(`phoneNumbers${getQueryString(data)}`)
  return response.data
}
export const getPhoneNumber = async (id: string): Promise<PhoneNumber> => {
  let response = await axios.get<PhoneNumber>(`phoneNumbers/${id}`)
  return response.data
}
export const createPhoneNumber = async (data: { [x: string]: any }): Promise<string> => {
  let response = await axios.post<string>(`phoneNumbers`, data)
  return response.data
}
export const updatePhoneNumber = async (id: string, data: { [x: string]: any }): Promise<string> => {
  let response = await axios.put<string>(`phoneNumbers/${id}`, data)
  return response.data
}
export const releasePhoneNumber = async (id: string): Promise<boolean> => {
  let response = await axios.post<boolean>(`phoneNumbers/${id}/release`)
  return response.data
}
export const createMessage = async (data: { [x: string]: any }): Promise<string> => {
  let response = await axios.post<string>(`messages`, data)
  return response.data
}
export const getAnalytics = async (data: { [x: string]: any }): Promise<Analytics> => {
  let response = await axios.get<Analytics>(`analytics${getQueryString(data)}`)
  return response.data
}
export const createUser = async (data: { [x: string]: any }): Promise<string> => {
  let response = await axios.post<string>(`users`, data)
  return response.data
}
export const updateUser = async (id: string, data: { [x: string]: any }): Promise<string> => {
  let response = await axios.put<string>(`users/${id}`, data)
  return response.data
}
export const getAvailableUsers = async (): Promise<Collection<User>> => {
  let response = await axios.get<Collection<User>>('users/available')
  return response.data
}